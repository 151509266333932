/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import urlBase64ToUint8Array from 'src/app/utilities/functions/urlBase64ToUint8Array';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerApiService {
  private _hasPermissionForNotifications$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private apiService: ApiService) {}

  public get hasPermissionForNotifications$() {
    return this._hasPermissionForNotifications$;
  }

  async sendServiceWorker() {
    navigator.serviceWorker
      .register('/assets/scripts/ngsw-worker.js', { scope: '/assets/scripts/' })
      .then((registration) => {
        console.log('Service Worker is registered');
        registration.pushManager.getSubscription().then((subscription) => {
          if (subscription) {
            console.log('User is subscribed.');
            this.apiService.sendSubscription(subscription).subscribe();
          } else {
            console.log('User is NOT subscribed.');
            this.makesubscribe(registration);
            this.hasPermissionForNotifications$.next(false);
          }
        });
      })
      .catch((err) => {
        console.error('Service Worker Error', err);
        this._hasPermissionForNotifications$.next(false);
      });
  }

  private makesubscribe(registration) {
    console.log('registration', registration);

    registration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(environment.vapidPublicKey),
      })
      .then((subscription) => {
        this._hasPermissionForNotifications$.next(true);
        this.apiService.sendSubscription(subscription).subscribe();
      })
      .catch((err) => {
        console.log('Subscription failed', err);
        this._hasPermissionForNotifications$.next(false);
      });
  }
}
