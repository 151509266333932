/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { USERTYPE } from 'src/app/utilities/constants';
import dateInPast from 'src/app/utilities/functions/date-in-past';
import { IEventWithUserCheck } from 'src/app/utilities/interfaces/Event';
import { ILiveUpdateCheck } from 'src/app/utilities/interfaces/live-update-check';
import { IGetUser, IUser } from 'src/app/utilities/interfaces/user';
import { ApiService } from '../api/api.service';
import { AllUsersService } from './all-users.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user$: BehaviorSubject<IUser> = new BehaviorSubject(null);

  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _error$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _syncUserloading$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  private _showAdminEvents$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  private _events$: BehaviorSubject<IEventWithUserCheck[]> =
    new BehaviorSubject(null);

  private _eventId$: BehaviorSubject<string> = new BehaviorSubject(null);

  private _showHistory$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private apiService: ApiService,
    private allUsersService: AllUsersService
  ) {
    this.loadUser();
  }

  public get user$() {
    return this._user$.asObservable();
  }

  public get loading$() {
    return this._loading$.asObservable();
  }

  public get error$() {
    return this._error$.asObservable();
  }

  public get userType$() {
    return this._user$.pipe(map((user) => (user ? user.type : null)));
  }

  public get syncLoading$() {
    return this._syncUserloading$.asObservable();
  }

  public get showHistory$() {
    return this._showHistory$.asObservable();
  }

  public get historicalEvents$() {
    return this._events$.pipe(
      filter((events) => !!events),
      withLatestFrom(this._showHistory$),
      map(([events, showHistory]) =>
        events.filter(
          (event) => dateInPast(new Date(event.endDate)) && showHistory
        )
      ),
      map((events) =>
        events.sort(
          (a, b) => (+new Date(b.endDate) - +new Date(a.endDate)) as any
        )
      )
    );
  }

  public get filteredEvents$() {
    return this._events$.pipe(
      filter((events) => !!events),
      withLatestFrom(this._showAdminEvents$),
      map(([events, showAdminEvents]) =>
        events.filter((event) => showAdminEvents || event.isUserEvent)
      )
    );
  }

  public get showingAdminEvents$() {
    return this._showAdminEvents$.asObservable();
  }

  public get eventById$() {
    return this._events$.pipe(
      filter((events) => !!events),
      withLatestFrom(this._eventId$),
      map(([events, id]) => events.find((event) => event.id === id))
    );
  }

  public getUserOrganizerEvents(id: string) {
    return this._events$.pipe(
      map((events) =>
        events.filter((event) =>
          event.organizers.some((organizer) => organizer.id === id)
        )
      )
    );
  }

  public showAdminEvents$() {
    this._showAdminEvents$.next(!this._showAdminEvents$.value);
  }

  public showHistory() {
    this._showHistory$.next(!this._showHistory$.value);
  }

  public eventId$(eventId: string) {
    this._eventId$.next(eventId);
  }

  async updateUser(id: string, type: string) {
    try {
      const user = await this.apiService
        .updateUser(id, type)
        .pipe(take(1))
        .toPromise();
      this.allUsersService.setNewUser(user);
      return user;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  updateUserEvents(id: string, value: IGetUser) {
    const events = this._events$.value.filter((event) =>
      event.organizers.some((organizer) => organizer.id === id)
    );
    events.forEach((event) => {
      event.organizers.push(value);
    });
  }

  public async syncUsers() {
    this._syncUserloading$.next(true);
    const response = await this.apiService
      .manualSyncUserInDb()
      .pipe(take(1))
      .toPromise();
    this._syncUserloading$.next(false);
    return response;
  }

  public updateCheckIn(updatedUser: ILiveUpdateCheck) {
    const events = this._events$.value;
    events
      .find((event) => event.id === updatedUser.event)
      .users.find((user) => user.id === updatedUser.user).isChecked =
      updatedUser.isCheckedIn;

    this._events$.next(events);
  }

  public addEvent(event: IEventWithUserCheck) {
    this._events$.next([...this._events$.value, event]);
  }

  public loadUser() {
    this._loading$.next(true);
    this.apiService.getUser().subscribe(
      (user) => {
        const events = user.events.map(
          (event) =>
            ({
              ...event,
              isUserEvent: true,
            } as IEventWithUserCheck)
        );
        this._user$.next(user);
        if (user.type.includes(USERTYPE.ADMIN)) {
          this.loadAdminEvents(events);
        } else {
          this._events$.next(events);
          this._loading$.next(false);
        }
      },
      (error) => {
        console.error(error);
        this._error$.next(true);
        this._loading$.next(false);
      }
    );
  }

  private loadAdminEvents(events: IEventWithUserCheck[]) {
    this.apiService.getAllEvents().subscribe((eventresponse) => {
      const eventswithAdmin = eventresponse.map(
        (event) =>
          ({
            ...event,
            isUserEvent: events.some((e) => e.id === event.id),
          } as IEventWithUserCheck)
      );
      this._events$.next(eventswithAdmin);
      this._loading$.next(false);
    });
  }
}
