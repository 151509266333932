import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { ILiveUpdateCheck } from 'src/app/utilities/interfaces/live-update-check';
import { UserService } from '../user/user.service';
import { environment } from './../../../environments/environment';

const socket = io(environment.apiUrl, { transports: ['websocket'] });

@Injectable({
  providedIn: 'root',
})
export class LiveUpdateService {
  constructor(private userService: UserService) {
    this.getNewUserCheckinUpdate();
  }

  public getNewUserCheckinUpdate() {
    socket.on('checkin-update', (data: ILiveUpdateCheck) => {
      this.userService.updateCheckIn(data);
    });
  }

  public sendCheckInUpdate(data: ILiveUpdateCheck) {
    socket.emit('checkin-update-client', data);
  }
}
