import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';
import { IEventWithUserCheck } from '../interfaces/Event';

@Injectable({
  providedIn: 'root',
})
export class EventResolver {
  constructor(private userService: UserService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<IEventWithUserCheck | Promise<boolean>> {
    this.userService.eventId$(route.params.id);
    return this.userService.eventById$.pipe(
      map((event) => event || this.router.navigate(['/dashboard'])),
      take(1),
    );
  }
}
