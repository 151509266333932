/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import {
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CheckForUpdatesService } from './services/check-updates/check-for-updates.service';
import { interceptorBarrel } from './utilities/interceptors/interceptorBarrel';
import { RoleGuard } from './utilities/guards/role-guard';
import localeBE from '@angular/common/locales/nl-BE';
registerLocaleData(localeBE, 'nl-BE');

export function loggerCallback(_logLevel: any, message: any) {
  console.log(message);
}

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    AppRoutingModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.clientId,
          authority: environment.authority,
          redirectUri: window.location.origin,
          postLogoutRedirectUri: environment.redirectUrl,
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: isIE,
        },
        system: {
          loggerOptions: {
            piiLoggingEnabled: true,
            logLevel: LogLevel.Info,
          },
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ['user.read'],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          ['/api/*', [`${environment.clientId}/.default`]],
        ]),
      },
    ),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'nl-BE' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    ...interceptorBarrel,
    MsalGuard,
    RoleGuard,
    CheckForUpdatesService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
