import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICheckResponse, IGetEvents } from 'src/app/utilities/interfaces/Event';
import {
  IGetUser,
  ISyncResponse,
  IUser,
} from 'src/app/utilities/interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getUser() {
    return this.http.get<IGetUser>('/api/users/me');
  }

  getAllUsers() {
    return this.http.get<IUser[]>('/api/users');
  }

  getAllEvents() {
    return this.http.get<IGetEvents[]>('/api/events');
  }

  updateCheckUser(eventId: string, userId: string, check: boolean) {
    const queryParams = new HttpParams().append('userId', userId);

    return this.http.patch<ICheckResponse>(
      `/api/events/${eventId}/check`,
      { check },
      { params: queryParams }
    );
  }

  checkAll(eventId, check) {
    return this.http.patch(`/api/events/${eventId}/check-all`, { check });
  }

  createEvent(eventData: any) {
    return this.http.post<IGetEvents>('/api/events', eventData);
  }

  manualSyncUserInDb() {
    return this.http.get<ISyncResponse>('/api/users/sync');
  }

  updateEvent(eventData: any) {
    return this.http.put('/api/events', eventData);
  }

  deleteEvent(eventId: string) {
    return this.http.delete(`/api/events/${eventId}`);
  }

  alertUser(userId: string, eventId: string) {
    const queryParams = new HttpParams().append('eventid', eventId);
    return this.http.get<ISyncResponse>(`/api/users/${userId}/alert`, {
      params: queryParams,
    });
  }

  updateUser(id: string, type: string) {
    return this.http.patch<IGetUser>(`/api/users/${id}/role`, { type });
  }

  sendSubscription(subscription: PushSubscription) {
    return this.http.post(
      '/api/web-push-subscription',
      JSON.stringify(subscription),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }
}
