/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IGetUser, IUser } from 'src/app/utilities/interfaces/user';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class AllUsersService {
  private _users$: BehaviorSubject<IUser[]> = new BehaviorSubject([]);

  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _error$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private apiService: ApiService) {}

  public get users$() {
    return this._users$.asObservable();
  }

  public get loading$() {
    return this._loading$.asObservable();
  }

  public get error$() {
    return this._error$.asObservable();
  }

  public getUser(id: string) {
    return this._users$.value.find((user) => user.id === id);
  }

  setNewUser(user: IGetUser) {
    const users = [...this._users$.value];
    const index = users.findIndex((u) => u.id === user.id);
    if (index === -1) {
      users.push(user);
    } else {
      users[index] = user;
    }
    this._users$.next(users);
  }

  public loadAllUsers() {
    this._loading$.next(true);
    this.apiService.getAllUsers().subscribe(
      (users) => {
        this._users$.next(users);
        this._loading$.next(false);
      },
      (error) => {
        console.error(error);
        this._error$.next(true);
        this._loading$.next(false);
      });
  }
}
