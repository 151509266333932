import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { USERTYPE } from './utilities/constants';
import { RoleGuard } from './utilities/guards/role-guard';
import { EventResolver } from './utilities/resolvers/event.resolver';

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'manage-users',
    canActivate: [MsalGuard, RoleGuard],
    data: {
      role: USERTYPE.SUPER_ADMIN,
    },
    loadChildren: () =>
      import('./manage-users/manage-users.module').then(
        (m) => m.ManageUsersModule,
      ),
  },
  {
    path: 'event/:id',
    canActivate: [MsalGuard],
    resolve: { EventResolver },
    loadChildren: () =>
      import('./event/event.module').then((m) => m.EventModule),
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];
const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false,
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
