export const environment = {
  production: true,
  baseUrl: 'https://proud-sea-04a9b3903.3.azurestaticapps.net',
  clientId: '47944d88-5350-426f-bb5c-9cc537bc26ad',
  AdGroupId: 'afd55302-6d57-4ed7-8187-8058b8cba273',
  authority: 'https://login.microsoftonline.com/0b53d2c1-bc55-4ab3-a161-927d289257f2/',
  redirectUrl: 'https://proud-sea-04a9b3903.3.azurestaticapps.net',
  apiUrl: 'https://euri-checkin-api.azurewebsites.net',
  vapidPublicKey: 'BKXBbW41k3S0d2KkO-hwv2_qlDLi38WjrlBzLGhJkM6gU6eyGzIkyU4x0rmH1xrf4zc-JCqVvJVUpZG83ZWz-Rk', // web push notification key,
  mapboxApiKey: 'pk.eyJ1IjoiZ3J5czUxIiwiYSI6ImNsMnE0aXZrZTF3dHQzZG5xZGlsbm5za2sifQ.H2DBjqJDa4TkrhBsAsrO-Q',
};
