/* eslint-disable no-underscore-dangle */
import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MsalService } from '@azure/msal-angular';
import { ToastController } from '@ionic/angular';
import { ServiceWorkerApiService } from './services/api/service-worker-api.service';
import { LiveUpdateService } from './services/websocket/live-update.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isIframe = false;

  constructor(
    private authService: MsalService,
    private toastController: ToastController,
    private serviceWorkerService: ServiceWorkerApiService,
    private liveUpdateService: LiveUpdateService,
    swUpdate: SwUpdate,
  ) {
    if (swUpdate.isEnabled) {
      swUpdate.versionUpdates.subscribe(() => {
        this.presentToast(() => {
          swUpdate.activateUpdate().then(() => document.location.reload());
        });
      });
    }
  }

  async ngOnInit() {
    const loggedIn = this.authService.instance.getAllAccounts().length > 0;

    if (!loggedIn) {
      this.authService.loginRedirect();
    }

    if ('serviceWorker' in navigator) {
      this.serviceWorkerService.sendServiceWorker();
    }
  }

  async presentToast(handler) {
    const toast = await this.toastController.create({
      message: 'Een nieuwe versie is beschikbaar!',
      color: 'warning',
      icon: 'cloud-download-outline',
      buttons: [
        {
          side: 'end',
          text: 'Haal laatste versie',
          role: 'cancel',
          handler,
        },
      ],
    });
    toast.present();
  }
}
