import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  public get userType$() {
    return this.userService.userType$;
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.userType$.pipe(
      filter((type) => !!type),
      map(type => type === route.data.role || this.router.createUrlTree(['/'])),
      take(1),
    );
  }
}
